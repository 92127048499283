.outerBarStyle {
    width: 100%;
    background-color: #ebebeb;
    border-radius: 50px;
    margin-left: 3rem;
    margin-right: 3rem;
    transition: height 0.2s ease-in-out;
}

.innerBarStyle {
    height: 100%;
    width: 0px;
    background-color: #ff00ff;
    border-radius: inherit;
}

.innerBarAccent {
    height: 40%;
    transform: translateY(2px);
    background-color: #ffffff40;
    border-radius: inherit;
    margin-left: min(15%, 3px);
    margin-right: min(15%, 3px);
}