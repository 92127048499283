/*-- Main: Question & Answer section --*/
.questionSection {
  padding: 0rem 1rem;
}

.question-counter {
  margin-top: 1rem;
  text-align: left;
  color: var(--text-secondary);
  font-size: 1.5em;
}

.question-text {
  font-weight: bold;
  text-align: left;
  line-height: 3rem;
  font-size: 2em;
}

.answerSection {
  padding-left: 0.5rem;
}

/* --- Speech bubble ---- */
.answerBox {
  color: var(--text-primary);
  text-align: left;
  padding: 4.5rem 0rem 1.5rem;
  margin: 2rem 0rem;
  background-color: #ebebeb;
  position: relative;
  border-radius: 10px;
}

/* This is the little Speech Bubble triangle in the bottom left of the answerBox */
.answerBox::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 60px solid #ebebeb;
  left: -50px;
  bottom: 8px;
  background-color: transparent;
}

/* --- relevance likert --- */
.question-relevance {
  max-height: 35%;
  margin: auto;
  position: relative;
  padding-top: 1rem;
  background-color: #f8f8f8;
  border-radius: 10px;
}

#relevance-text {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.relevance-likert {
  max-width: 70%;
  position: relative;
  padding-bottom: 2rem;
}

/*----- Likert Scale  -----*/
.likertBand {
  max-width: 100%;
}

/* 
 Many things are marked as !important here: Why?
 The Style of the Likert Skala is set inline in the library.
 During Development, the css overwrites the inline, but when deployed, the inline overwrites the css
 This means that we need to set the css to be more important than the inline. Hence the !important
*/

.likertLine {
  display: inline-block;
  max-width: 50% !important;
  max-height: 0px;
  vertical-align: baseline !important;
  padding-bottom: 0.5rem !important;
  border-top: 2px solid #8192A6 !important;
}

.likertIndicator {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  border-radius: 3px !important;
  border: thin solid #8192A6 !important;
  background-color: #ffff !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1px !important;
  /* One Pixel Moving to the top needed since the line is 2px thick and the checkbox needs to be in the middle of it*/
  box-sizing: border-box;
  transition: all linear 200ms;
}

.likertResponse {
  height: 4rem;
}

.likertResponse:hover .likertIndicator {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: var(--buttons-primary-hover) !important;
  border: thin solid var(--buttons-primary-hover) !important;
  border-width: 3px !important;
  top: -4px !important;
  transition: all linear 300ms !important;
  cursor: pointer;
}

.likertText {
  color: var(--text-secondary);
  display: inline-table !important;
  width: 80% !important;
  line-height: 1.2rem;
  box-sizing: border-box !important;
  text-align: center;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translateX(50%);
}

.likertScale.isKeyboardUser .likertResponse>input:focus~.likertIndicator {
  /* Show outline for keyboard users. */
  box-shadow: 0 0 5px 2px var(--buttons-primary);
}

.likertResponse>input:checked+.likertIndicator {
  width: 20px !important;
  height: 20px !important;
  top: -1.5px !important;
  border-radius: 50% !important;
  background-color: var(--buttons-primary-hover) !important;
}

.likertResponse>input:checked+.likert {
  display: inline-block;
  width: 50%;
  border-top: 2px solid var(--buttons-primary) !important;
}


/* ---  Media query For Mobile & Tablet size ---- */
/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 576px) {
  .question-text {
    line-height: 2rem;
  }

  .questionSection {
    min-height: 9rem;
  }

  .answerBox {
    padding: 1rem 0rem 1rem;
  }

  .answerBox::after {
    content: '';
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 0px;
    background-color: transparent;
  }

  .answerSection {
    padding-left: 12px;
  }

  .question-relevance {
    max-height: 50%;
  }

  .relevance-likert {
    max-width: 90%;
    position: relative;
    padding-bottom: 1rem;
  }

}


@media only screen and (min-width : 577px) and (max-width:767px) {
  .answerBox {
    padding: 1rem 0rem 1rem;
  }

  .questionSection {
    min-height: 12rem;
  }

  .answerBox::after {
    content: '';
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 0px;
    background-color: transparent;
  }

  .answerSection {
    padding-left: 12px;
  }

  .question-relevance {
    max-height: 50%;
  }

  .relevance-likert {
    padding-bottom: 0rem;
  }

}

/* sm:768≤ x,  ms:991px ≤ x ----------- */
@media only screen and (min-width : 768px) and (max-width : 991px) {

  .question-relevance {
    max-height: 50%;
  }

  .relevance-likert {
    padding-bottom: 1rem;
  }

}