/* Background Image. Absolute Size, doesn't move when screen is scrolling */
.welcome-bg {
    position: absolute;
    overflow: auto;
    background-image: var(--welcome-background-img);
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    background-position: center;
    /* Needs to be scroll instead of fixed for Mobile Android
        Otherwise, when swiping away the menu bar, the additional space won't be filled by the background image
    */
    background-attachment: scroll;
}

/* Applied to app-body. Makes the grey box slightly transparent so the picture can still be seen */
.welcome-screen-colour {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

/* Applied to WelcomeText, so that it's in the Center */
.text-in-center {
    text-justify: inter-word;
    margin: 2rem;
    /* Left and Right auto margin needed for Chrome so that enumeration isn't on far left side */
    margin-left: auto;
    margin-right: auto;
    height: unset !important;
    width: 50%;
}

/* Applied to big Logo so that it is in the Center as well */
.center {
    display: block;
} 




/* ---  Media query For Mobile & Tablet size ---- */
@media only screen and (max-width : 576px){ 
    .text-in-center {
        width: 90%;
    }
}
  
@media only screen and (min-width : 577px) and (max-width : 991px){
    .text-in-center {
        width: 75%;
    }
} 