.app-body {
    /* Lock width at 2000px so ultrawides don't get a problem */
    max-width: min(70%, 2000px);
    /* Box is at least 85 viewport percent high, more if needed by content */
    min-height: 85vh;
    background-color: #fff;
    border-radius: 10px;
    /* Flexing needed so the flexbox containing body and buttons gets all their space */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.wrapper {
    /* wrapper for positioning kind of in center vertically */
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/* ---  Media query For Mobile & Tablet size ---- */
/* Sizes according to https://getbootstrap.com/docs/5.1/layout/breakpoints/ */

/* Phones, prefix col-* */
@media only screen and (max-width : 576px) {
    /*
    Same as col-sm, but I'll keep it here for now if I need to add differences in the future 
    */

    .app-body {
        min-width: 100%;
        min-height: 100vh;
        border-radius: unset;
    }

    .wrapper {
        margin-top: 0%;
        margin-bottom: 0%;
    }
}

/* prefix col-sm-* */
@media only screen and (min-width : 577px) and (max-width:767px) {
    /* Phone/Tablet View
        No box in the middle anymore, but quiz fills whole screen
        manual margins have to be set because of that (formerly given by bootstraps row, not anymore though)
        Width of the body is sized accordingly, subtracting the margins
        For Reasons unknown, it needs to be min-width, otherwise it's not wide enough ._. same with height

        Wrapper is Disabled completely, since no illusion of vertical middle placement needs to happen anymore
    */

    .app-body {
        min-width: 100%;
        min-height: 100vh;
        border-radius: unset;
    }

    .wrapper {
        margin-top: 0%;
        margin-bottom: 0%;
    }

}

/* prefix col-md-* */
@media only screen and (min-width : 768px) and (max-width : 991px) {
    .app-body {
        /* if the screen is beginning to get small, increase used size */
        max-width: 90%;
    }
}