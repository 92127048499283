.footer-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.footer-image {
    width: 10rem;
    max-height: 10rem;
    margin: 0.2rem;
}

.footer-text {
    text-align: center;
    margin: 0.2rem;
    width:8rem;
    color: var(--buttons-secondary) !important;
    text-decoration: underline;
}

.footer-a {
    color: var(--buttons-secondary) !important;
}