#backButton {
    padding: 0.4rem 1.8rem;
    width: 9rem;
    height: 2.4rem;
    background-color: var(--buttons-secondary);
    border-color: var(--buttons-secondary);
    color: var(--buttons-secondary-font);
    /* font-weight: bold; */
}

#backButton:hover {
    background-color: var(--buttons-secondary-hover);
    border-color: var(--buttons-secondary-hover);
}

#nextButton {
    padding: 0.4rem 1.8rem;
    width: 9rem;
    height: 2.4rem;
    font-style: normal;
    text-align: center;
    border-color: var(--buttons-primary);
    background-color: var(--buttons-primary);
    color: var(--buttons-primary-font);
    /* font-weight: bold; */
}

#nextButton:hover {
    background-color: var(--buttons-primary-hover);
    border-color: var(--buttons-primary-hover);
}

#skipButton {
    /* Don't know why this is the case here, but the extra padding breaks "Überspringen" */
    /* padding: 0.4rem 1.8rem; */
    width: 9rem;
    height: 2.4rem;
    font-style: normal;
    text-align: center;
    border-width: 0px;
    background-color: var(--buttons-primary);
    filter: opacity(50%);
    color: var(--buttons-primary-font);
    /* font-weight: bold; */
}

#skipButton:hover {
    background-color: var(--buttons-primary-hover);
    border-color: var(--buttons-primary-hover);
    filter: opacity(100%);
}

#result-backButton {
    padding: 0.4rem 1.8rem;
    font-weight: 400;
    width: 9rem;
    height: 2.4rem;
    font-style: normal;
    text-align: center;
    background-color: var(--buttons-secondary);
    border-color: var(--buttons-secondary);
    color: var(--buttons-secondary-font);
}

#result-backButton:hover {
    background-color: var(--buttons-secondary-hover);
    border-color: var(--buttons-secondary-hover);
}

#result-nextButton {
    padding: 0.4rem 1.8rem;
    width: 9rem;
    height: 2.4rem;
    font-style: normal;
    text-align: center;
    border-color: var(--buttons-primary);
    background-color: var(--buttons-primary);
    color: var(--buttons-primary-font);
}

#result-nextButton:hover {
    background-color: var(--buttons-primary-hover);
    border-color: var(--buttons-primary-hover);
}

/* ---  Media query For Mobile & Tablet size ---- */
/* Sizes according to https://getbootstrap.com/docs/5.1/layout/breakpoints/ */

/* Phones, prefix col-* */
@media only screen and (max-width : 576px) {
    #nextButton {
        width: 7rem;
        padding: 0.4rem;
    }

    #backButton {
        width: 7rem;
        padding: 0.4rem;
    }

    #skipButton {
        width: 7rem;
        padding: 0rem;
    }
}

@media only screen and (max-width:767px) {

    #result-backButton {
        width: 7rem;
        padding: 0.4rem;
        margin-bottom: 3rem;
    }

    #result-nextButton {
        width: 7rem;
        padding: 0.4rem;
        margin-bottom: 3rem;
    }
}