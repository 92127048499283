/*-- Main: Question & Answer section --*/

.question-relevance {
  max-height: 90%;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.answerSection {
  align-content: center;
  display: block;
}

#relevance-text {
  margin-top: 2rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.relevance-likert {
  max-width: 70%;
  position: relative;
  padding-bottom: 0rem;
}

.questionSection .likertResponse {
  margin-bottom: -1rem;
}

.criterionSection {
  height: 100%;
  display: block;
  align-content: center;
}

.question-counter {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  color: var(--text-secondary);
  font-size: 1em;
}

.question-text {
  font-weight: bold;
  text-align: left;
  line-height: 2rem;
  font-size: 1.5em;
}

.criterion-text {
  font-weight: bold;
  text-align: unset;
  line-height: 2rem;
  font-size: 1.5em;
}

.endRelevanceWrapper {
  color: var(--text-primary);
  /* padding: 4.5rem 0rem 1.5rem; */
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  background-color: #ebebeb;
  position: relative;
  border-radius: 10px;
}

.relevanceCheckboxWrapperBox {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relevanceCheckbox {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 5px !important;
  border: thin solid #8192A6 !important;
  transition: all linear 200ms;
}

.relevanceCheckbox:hover {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  background-color: var(--buttons-primary-hover);
  border: thin solid var(--buttons-primary-hover);
  border-width: 3px;
  transition: all linear 300ms !important;
  cursor: pointer;
}

.relevanceCheckbox:focus {
  /* Show outline for keyboard users. */
  box-shadow: 0 0 5px 2px var(--buttons-primary);
}

.relevanceCheckbox:checked {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: var(--buttons-primary-hover);
}

.CheckmarkSVG {
  pointer-events: none;
  position: absolute;
  color: white;
}


.checkboxText {
  color: var(--text-secondary);
  line-height: 1.2rem;
  text-align: left;
  margin-left: 1em;
}

.textAlignLeft {
  text-align: left;
}

.CompactTextRight {
  text-align: right;
}

.CompactCube {
  width: 5rem;
  margin-left: 0;
}



@media only screen and (max-width : 768px) {
  .question-relevance {
    padding-bottom: 1em;
  }

  .CompactTextRight {
    text-align: center;
  }

  .CompactCube {
    width: unset;
    margin-left: unset;
  }

  .criterionSection {
    margin-top: 0.5em;
  }
}