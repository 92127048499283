.header {
    padding-top: 0.5rem;
}

.unisportLogo {
    padding: 1rem;
    max-height: 150px;
    max-width: 150px;
    object-fit: scale-down;
}

.language_flag {
    max-width: 30px;
    max-height: 30px;
}

.language_button {
    padding-left: 0px;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-bottom: 8px;
    max-width: 30px;
    max-height: 30px;
    border: none;
    background: none;
}

.language_button_selected {
    padding-left: 0px;
    margin-top: calc(1rem + 8px);
    margin-left: 1rem;
    max-width: 30px;
    max-height: 30px;
    padding-bottom: 3px;
    border: none;
    background: none;
    border-bottom: var(--buttons-primary) solid 5px;
}