#snack-image {
  width: 25rem;
  height: 25rem;
  object-fit: scale-down;
  margin-right: 3rem;
  margin-top: 2rem;
}

/* Other than the missing text-align equal to activity text */
.wissenssnack-text {
  font-family: Roboto;
  font-style: normal;
  padding-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  font-size: 2em;
}

/*----  Speech Ballon left side ---- */
#snack-bubble,
#snack-bubble::before,
#snack-bubble::after {
  background: #ebebeb;
}

#snack-bubble {
  position: relative;
  border-radius: 20px;
}

#snack-bubble::after {
  content: '';
  position: absolute;
  display: block;
  z-index: 0;

  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 100px solid #ebebeb;

  right: -30px;
  bottom: 0px;
  border-radius: 50%;
  background-color: transparent;
}


/* ---  Media query For Mobile & Tablet size ---- */
@media only screen and (max-width : 576px) {
  #snack-bubble {
    padding: 1.5rem;
  }

  #snack-bubble::after {
    display: none;
  }

  #snack-image {
    float: none;
    width: unset;
    height: unset;
    object-fit: scale-down;
    margin-right: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 0rem;
  }
}

@media only screen and (min-width : 577px) and (max-width:767px) {
  #snack-bubble {
    padding: 1.5rem;
    position: relative;
  }

  #snack-bubble::after {
    display: none;
  }

  #snack-image {
    float: unset;
    width: unset;
    height: unset;
    object-fit: scale-down;
    margin-right: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 0rem;
  }

}

/* 
Not needed right now
@media only screen and (min-width : 769px) and (max-width : 991px){
} */