@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

:root {
    --buttons-primary: #99CC01;
    --buttons-primary-hover: #6b9e1f;
    --buttons-secondary: #003466;
    --buttons-secondary-hover: #003566c7;

    --primary-progress-bar: #99CC01;
    --secondary-progress-bar: #003466;

    --text-primary: #003466;
    --text-secondary: #7ea800;

    --result-list-primary: #003466;
    --result-list-secondary: #d7f0b3b7;

    --result-list-criterion-bg-colour: #003466;
    --result-list-criterion-text-colour: #ffffff;

    --welcome-background-img: "";

    --buttons-primary-font: #fff;
    --buttons-secondary-font: #fff;
}


#footer {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    color: var(--text-primary);
    background-color: #e6e6e6;
}




html,
body {
    height: 100%;
}

div#root {
    height: 100vh;
}

img {
    /* These Sizes are used for Activity and Snack images in Phone Layout */
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
}

/* textfield used by Activity and Snacks */
.textfield {
    background-color: #ebebeb;
    border-radius: 10px;
    margin: 2rem;
    padding: 1rem;
    min-height: 80%;
    max-width: 50rem;
    /* Holy CSS, Batman!
    This Calc mass centers the Activity/Snack Text with a max width of 50 rem,
    but still allows the 2rem margin if the textfield is squished on by a smaller screen 
    */
    margin-left: max(calc((100% - 50rem) / 2), 2rem);
    margin-right: max(calc((100% - 50rem) / 2), 2rem);
}

/* We use the quill css classes to format the rich text
Sadly, quill does some stupid stuff, like automatic padding and 100% height
Use this class in conjunction with ql-editor to prevent these things */
.anti-quill {
    padding: 0px !important;
    height: unset !important;
    text-justify: inter-word;
    overflow: hidden;
}


/* Links in all Pages except ResultPage (which has its own css) */
a:link {
    color: var(--text-secondary);
    /* text-decoration: none; */
}

a:visited {
    color: var(--text-secondary);
    filter: brightness(90%);
}

a:hover {
    color: var(--text-secondary);
    filter: brightness(110%);
}

a:active {
    color: var(--text-secondary);
    filter: brightness(90%);
}

/* Increase all Paragraph Line Heights to 1.5, as is wanted by accessibility tools
Just makes everything more readable, eh? */
p {
    line-height: 1.5;
}

/* Used by the Snackbar. Since it is a button element (to be clickable by Enter and Space), it uses some of the button CSS.
Setting border to none fixes the style change, but prevents a border from being drawn if the snackbar is focused. So, the border attribute is unset what that happens.
*/
.noButton {
    border: none;
}

.noButton:focus {
    border: unset;
}