@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

.result-left {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    width: 100%;
}

.result-text {
    margin-left: 3rem;
    margin-right: 3rem;
}

.result-text-bottom {
    margin: 3rem;
}

.result-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 2rem;
}

/* -- table result List -- */
.list-title {
    background-color: var(--result-list-primary);
    border-color: var(--result-list-primary);
    color: var(--result-list-title-font);
    font-size: large;
}

.list-item {
    background-color: var(--result-list-secondary);
    color: var(--result-list-primary);
    animation: FadeIn ease-in 1 1.5s;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.list-item:hover {
    background-color: #f7f7f7;
}

.badge-result {
    background-color: var(--result-list-criterion-bg-colour);
    /* color: var(--result-list-criterion-text-colour) !important; */
    font-size: 0.65em;
}

.result-sports-list {
    min-height: 50rem;
    margin-bottom: 3rem;
}

.custom-link {
    text-decoration: none;
}

.custom-link:focus {
    filter: brightness(120%);
}

.custom-link-col:link {
    color: var(--result-list-primary);
    filter: brightness(120%);
}

.custom-link-col:visited {
    color: var(--result-list-primary);
    filter: brightness(80%);
}

.custom-link-col:hover {
    color: var(--result-list-primary);
    filter: brightness(150%);
}

.custom-link-col:active {
    color: var(--result-list-primary);
    filter: brightness(140%);
}