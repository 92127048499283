#activity-image {
    width: 25rem;
    height: 25rem;
    object-fit: scale-down;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-top: 2rem;
}

/* Container for that grey Activity Text thing
    Needs a bit of a bottom margin when in Phone mode, to allow space for the Skip Button
*/
.text-box {
    margin-bottom: 1rem;
}

.activity-text {
    font-family: Roboto;
    font-style: normal;
    text-align: center;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    font-size: 2em;
}

/*  Where the Startbutton and Skip Button are in, only if both are rendered
    The Negative Top Margin moves the buttons so that the Start Button is in the position of the original Next Button
*/
.start-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 6rem;
    margin-top: -3.6rem;
}

/* ---  Media query For Mobile & Tablet size ---- */

/* Phones, prefix col-* */
@media only screen and (max-width : 576px) {
    #activity-image {
        float: none;
        width: unset;
        height: unset;
        object-fit: scale-down;
        margin-left: 0rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .text-box {
        margin-bottom: 4rem;
    }
}

/* prefix col-sm-* */
@media only screen and (min-width : 577px) and (max-width:767px) {
    #activity-image {
        float: unset;
        width: unset;
        height: unset;
        object-fit: scale-down;
        margin-left: 0rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .text-box {
        margin-bottom: 4rem;
    }
}